<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" :before-close="beforeClose" v-model="form" ref="crud" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange"
                   @row-update="rowUpdate" @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="primary" size="small" icon="el-icon-circle-plus" plain @click="addGnrlCard">新增普卡
                </el-button>
                <el-button type="primary" size="small" icon="el-icon-plus" plain @click="addTimeCard">新增年卡
                </el-button>
                <el-button type="primary" size="small" icon="el-icon-back" plain @click="handleBack">返 回
                </el-button>
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.epmalladdress_delete" @click="handleDelete">删 除
                </el-button>
            </template>
            <template slot-scope="{ row }" slot="menu">
                <template v-if="row.approvalStatus == 3">
                    <el-button @click="updateForApprovalStatus(row)" icon="el-icon-edit" size="mini" type="text">编辑</el-button>
                    <el-button v-if="
              row.cardType == 3 ||
              row.cardType == 4 ||
              row.cardType == 5 ||
              row.cardType == 6
            " @click.stop="cardYQ(row)" icon="el-icon-tickets" size="mini" type="text">延期</el-button>
                </template>
                <el-button @click.stop="dolist(row)" icon="el-icon-tickets" size="mini" type="text">延期记录</el-button>
                <el-button @click.stop="purchase(row)" icon="el-icon-tickets" size="mini" type="text">消费记录</el-button>
                <el-button type="text" size="small" icon="el-icon-circle-check" @click="showTaskInstance(row)">审批记录
                </el-button>
            </template>
        </avue-crud>
        <el-dialog title="会员卡延期" :visible.sync="dialogVisible" v-if="dialogVisible" width="40%" append-to-body>
            <el-form ref="form" label-width="80px">
                <el-form-item label="延期天数">
                    <el-input-number size="small" v-model="card_yq_num" :min="1"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="sure_yq_card()" size="mini" type="primary">确 认</el-button>
                <el-button @click="dialogVisible = false" size="mini" type="defaut">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="延期记录" :visible.sync="todoDialogVisible" v-if="todoDialogVisible" width="50%" append-to-body>
            <spcodaccardTodo :currentId="spcodaccardTodoId"></spcodaccardTodo>
            <span slot="footer" class="dialog-footer">
                <el-button @click="todoDialogVisible = false" size="mini" type="defaut">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="消费记录" :visible.sync="purchaseDialogVisible" width="70%" append-to-body>
            <SpcodAcCountDetail v-if="purchaseDialogVisible && currentRow" :cardId="currentRow.id"></SpcodAcCountDetail>
            <span slot="footer" class="dialog-footer">
                <el-button @click="todoDialogVisible = false" size="mini" type="defaut">取 消</el-button>
            </span>
        </el-dialog>
        <el-drawer :visible.sync="todoDrawer" :size="400" :withHeader="false" append-to-body>
            <el-container v-if="todoDrawer" class="h-100p">
                <el-header class="bor-b" height="50px">
                    <el-page-header class="lh-50" @back="todoDrawer = false" content="审批记录"></el-page-header>
                </el-header>
                <el-main class="pad-15">
                    <TodoTask :id="todoDrawerRow.processInstanceId"></TodoTask>
                </el-main>
            </el-container>
        </el-drawer>
    </basic-container>
</template>

<script>
import {
    getList,
    getDetail,
    add,
    update,
    remove,
    getPage,
    toogleStatus,
    fetchCardType,
    saveOverDate,
} from '@/api/spcod/spcodaccountcard';
import TodoTask from '@/views/flow/todo-task.vue';

import { getAccDetail, getUser, getCardType } from '@/api/spcod/spcodaccount';
import { number } from 'mathjs';
import { mapGetters } from 'vuex';
import { startFlow } from '@/api/flow/flow.js';
import { getPicListByIds } from '@/api/busiMode/metaCommon';
import spcodaccardTodo from './spcodaccardTodo';
import SpcodAcCountDetail from './spcodaccountdetail.vue';
export default {
    components: { spcodaccardTodo, TodoTask, SpcodAcCountDetail },
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            purchaseDialogVisible: false,
            dialogVisible: false,
            todoDrawer: false,
            todoDrawerRow: {},
            todoDialogVisible: false,
            card_yq_num: 1,
            cardTimeType: 'year',
            CARD_TIME_TYPE: 0, //默认为普通卡
            //默认非年卡的字段显示
            GENERAL_CARD_TAG: {
                timeType: 0,

                cardType: 1,
                cardValue: 1,
                giftValue: 1,
                effectFrm: 1,
                endDay: 1,
            },
            TIME_TYPE_DATA: [],
            CARD_TYPE_DATA: [],
            ALL_TYPE_DATA: [],
            allCardType: [],
            accId: '',
            accInfo: {},
            form: {},
            query: {},
            picList: [],
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                addBtn: false,
                delBtn: false,
                editBtn: false,
                viewBtn: true,
                dialogClickModal: false,
                labelWidth: 120,
                menuWidth: 250,
                column: [
                    {
                        label: '会员名称',
                        prop: 'vipName',
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        searchShow: true,
                        label: '会员电话',
                        prop: 'vipPhone',
                        addDisplay: false,
                        editDisplay: false,
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入联系人',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '会员卡号',
                        prop: 'cardNo',
                        search: false,
                        addDisplay: false,
                        editDisplay: false,
                        rules: [
                            {
                                required: false,
                                message: '请输入会员卡号',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '卡名称',
                        prop: 'cardName',
                        editDisabled: true,
                        search: false,
                        minWidth: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入会员卡名称',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '卡类型',
                        prop: 'timeType',
                        addDisplay: false,
                        viewDisplay: false,
                        editDisplay: false,
                        editDisabled: true,
                        type: 'select',
                        dicUrl: '/api/park-spcod/spcodaccountcard/getCardType',
                        props: {
                            label: 'value',
                            value: 'key',
                            res: 'data',
                        },
                        dataType: 'number',
                        dicData: [],
                        type: 'select',
                        rules: [
                            {
                                required: true,
                                message: '卡类型，0普通/1年卡/2半年卡/3季卡/4月卡',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column }) => {
                            let _this = this;
                            if (value == undefined || value == '') return;
                            let ci = 0;
                            this.changeData(value);
                            _this.option.column.forEach(c => {
                                if (c.prop == 'bizType') {
                                    this.form.bizType = '';
                                    _this.option.column[ci].dicData = [
                                        {
                                            label: '文体',
                                            value: 'SPT',
                                        },
                                    ];
                                    _this.form.bizType = 'SPT';
                                }
                                ci += 1;
                            });
                            _this.$forceUpdate();
                        },
                    },
                    {
                        label: '计费模式',
                        prop: 'cardType',
                        editDisabled: true,
                        search: false,
                        hide: true,
                        searchSpan: 4,
                        width: 130,
                        type: 'select',
                        dicUrl: '/api/park-spcod/spcodaccountcard/getCardType?type=0',
                        props: {
                            label: 'value',
                            value: 'key',
                            res: 'data',
                        },
                        dicData: [],
                        rules: [
                            {
                                required: true,
                                message: '请输入 1计时 1计次',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column }) => {
                            let _this = this;
                            if (value == undefined || value == '') return;

                            let ci = 0;
                            _this.option.column.forEach(c => {
                                if (c.prop == 'cardValue') {
                                    if (value == 2 || parseInt(value) == 2) {
                                        _this.option.column[ci].editDisplay = true;
                                        _this.option.column[ci].addDisplay = true;

                                        _this.option.column[ci].disabled = false;
                                        _this.option.column[ci].rules[0].required = true;
                                    } else {
                                        _this.option.column[ci].disabled = false;
                                        _this.option.column[ci].editDisplay = false;
                                        _this.option.column[ci].addDisplay = false;
                                        _this.option.column[ci].rules[0].required = false;
                                    }
                                }
                                if (c.prop == 'bizType') {
                                    this.form.bizType = '';
                                    if (value == 1 || parseInt(value) == 1) {
                                        //1计时 2计次
                                        _this.option.column[ci].dicData = [
                                            {
                                                label: '会场',
                                                value: 'COD',
                                            },
                                            {
                                                label: '文体',
                                                value: 'SPT',
                                            },
                                        ];
                                    } else {
                                        _this.option.column[ci].dicData = [
                                            {
                                                label: '文体',
                                                value: 'SPT',
                                            },
                                        ];
                                    }
                                }
                                if (c.prop == 'disRate') {
                                    _this.option.column[ci].addDisplay = true;
                                }
                                ci += 1;
                            });
                            _this.form.bizType = 'SPT';
                        },
                    },

                    {
                        label: '业务类型',
                        prop: 'bizType',
                        search: true,
                        searchSpan: 4,
                        editDisabled: true,
                        width: 130,
                        type: 'select',
                        dicData: [
                            {
                                label: '会场',
                                value: 'COD',
                            },
                            {
                                label: '文体',
                                value: 'SPT',
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请选择',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column }) => {
                            let _this = this;
                            if (value == undefined || value == '') return;
                            //只对普通卡有效
                            let dicData = [];
                            if (value == 'SPT') {
                                //只对普通卡有效
                                if (this.CARD_TIME_TYPE == 1) {
                                    //年卡
                                    _this.allCardType.forEach(r => {
                                        if (r.floorName == -1) {
                                            // if (r.id == 0) {
                                            dicData.push({ label: r.name, value: r.id });
                                            //  }
                                        }
                                    });
                                } else {
                                    _this.allCardType.forEach(r => {
                                        dicData.push({ label: r.name, value: r.id });
                                    });
                                }
                            } else {
                                //会场就选通用卡

                                //普通卡
                                _this.allCardType.forEach(r => {
                                    if (r.id == 0) {
                                        dicData.push({ label: r.name, value: r.id });
                                    }
                                });
                            }
                            let ci = 0;
                            _this.option.column.forEach(c => {
                                ci += 1;
                                if (c.prop == 'typeName') {
                                    _this.option.column[ci].dicData = dicData;
                                    _this.$forceUpdate();
                                }
                                if (c.prop == 'typeId') {
                                    _this.option.column[ci].dicData = dicData;
                                    _this.$forceUpdate();
                                }
                            });
                        },
                    },
                    {
                        label: '折扣',
                        prop: 'disRate',
                        editDisabled: true,
                        type: 'number',
                        search: false,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        min: 1,
                        max: 10,
                        step: 0.1,
                        rules: [
                            {
                                required: true,
                                message: '请输入折扣',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '场地类型',
                        prop: 'typeName',
                        editDisabled: true,
                        search: false,
                        addDisplay: false,
                        // editDisplay: false,
                        minWidth: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入场地类型',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '场地类型',
                        prop: 'typeId',
                        addDisplay: true,
                        editDisplay: false,
                        hide: true,
                        type: 'select',
                        viewDisplay: false,
                        search: true,
                        searchSpan: 4,
                        dicData: [],
                        rules: [
                            {
                                required: true,
                                message: '请输入场馆ID',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column }) => {},
                    },

                    {
                        label: '充值金额',
                        prop: 'faceValue',
                        editDisabled: true,
                        type: 'number',
                        search: false,
                        minWidth: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入充值金额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '卡面金额/次数',
                        prop: 'cardValue',
                        editDisabled: true,
                        search: false,
                        type: 'number',
                        width: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入卡面金额/次数',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '赠送金额/次数',
                        prop: 'giftValue',
                        editDisabled: true,
                        search: false,
                        type: 'number',
                        width: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入赠送金额/次数',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '余额',
                        prop: 'balanceValue',
                        search: false,
                        editDisabled: true,
                        type: 'number',
                        addDisplay: false,
                        editDisplay: false,
                        minWidth: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入余额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '开始时间',
                        width: 120,
                        prop: 'effectFrm',
                        editDisabled: true,
                        search: false,
                        type: 'date',
                        addDisplay: false,
                        editDisplay: true,
                        viewDisplay: true,
                        valueFormat: 'yyyy-MM-dd',
                        formar: 'yyyy-MM-dd',
                        rules: [
                            {
                                required: true,
                                message: '结束时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '结束时间',
                        prop: 'endDay',
                        editDisabled: true,
                        width: 120,
                        valueFormat: 'yyyy-MM-dd',
                        search: false,
                        type: 'date',
                        addDisplay: false,
                        editDisplay: true,
                        viewDisplay: true,
                        rules: [
                            {
                                required: false,
                                message: '结束时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '审批状态',
                        prop: 'approvalStatus',
                        search: true,
                        searchSpan: 4,
                        addDisplay: false,
                        editDisplay: false,
                        type: 'select',
                        dicData: [
                            {
                                value: 1,
                                label: '待提交',
                            },
                            {
                                value: 2,
                                label: '审批中',
                            },
                            {
                                value: 3,
                                label: '审批通过',
                            },
                            {
                                value: 4,
                                label: '审批未通过',
                            },
                            {
                                value: 5,
                                label: '已撤回',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        search: true,
                        searchSpan: 4,
                        addDisplay: false,
                        editDisplay: false,
                        type: 'select',
                        dicData: [
                            {
                                value: 1,
                                label: '有效卡',
                            },
                            {
                                value: 0,
                                label: '无效卡',
                            },
                            {
                                value: 2,
                                label: '制卡申请',
                            },
                            {
                                value: 3,
                                label: '退卡申请',
                            },
                        ],
                        rules: [
                            {
                                required: false,
                                message: '状态',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'memo',
                        search: false,
                        hide: true,
                        span: 24,
                        minRows: 4,
                        type: 'textarea',
                        editDisabled: true,
                        rules: [
                            {
                                required: false,
                                message: '请输入订购注意事项',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '创建时间',
                        prop: 'createTime',
                        search: false,
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入电话',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '操作人',
                        prop: 'creatorName',
                        search: false,
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入电话',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '头像',
                        prop: 'headFiles',
                        listType: 'picture',
                        hide: true,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        type: 'upload',
                        tip: '只能上传jpg/png用户头像，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                        span: 24,
                        rules: [
                            {
                                required: false,
                                message: '请上传图片',
                                trigger: 'change',
                            },
                        ],
                    },
                ],
            },
            data: [],
            currentRow: {},
            spcodaccardTodoId: '',
        };
    },
    created() {
        let _this = this;
        fetchCardType().then(res => {
            _this.allCardType = res.data.data;
        });
        this.getCardType();
    },
    watch: {
        'form.effectFrm'(val) {
            if (val) {
                let m = 1;
                if (parseInt(this.form.timeType) == 3) m = 12;
                else if (parseInt(this.form.timeType) == 4) m = 6;
                else if (parseInt(this.form.timeType) == 5) m = 3;
                else if (parseInt(this.form.timeType) == 6) m = 1;
                this.form.endDay = this.addMonth(this.dateToString(val), m);
            }
        },
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.epmalladdress_add, false),
                viewBtn: this.vaildData(this.permission.epmalladdress_view, false),
                delBtn: this.vaildData(this.permission.epmalladdress_delete, false),
                editBtn: this.vaildData(this.permission.epmalladdress_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    mounted() {
        let _this = this;
        this.accId = this.$router.currentRoute.query.accId;
        getAccDetail(this.accId).then(res => {
            _this.accInfo = res.data.data;
        });
        this.onLoad(this.page);
        //放在数据加载完后执行
        this.$refs.crud.dicInit('cascader');
    },
    methods: {
        showTaskInstance(obj) {
            console.log(obj);
            this.todoDrawerRow = obj;
            this.todoDrawer = true;
        },
        purchase(row) {
            this.currentRow = row;
            this.purchaseDialogVisible = true;
        },
        updateForApprovalStatus(row, index) {
            // console.log(this.$refs.crud);
            // this.$refs.crud.rowUpdate();
            //this.rowUpdate();
            console.log(row, index);
            this.$refs.crud.rowEdit(row, index);
        },
        cardYQ(row) {
            this.currentRow = row;
            this.dialogVisible = true;
        },
        dolist(row) {
            this.todoDialogVisible = true;
            this.spcodaccardTodoId = row.id;
        },
        sure_yq_card() {
            let obj = {
                ids: this.currentRow.id,
                overDay: this.card_yq_num,
            };
            saveOverDate(obj).then(res => {
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '延期成功!',
                    });
                    this.dialogVisible = false;
                    this.card_yq_num = 1;
                }
            });
        },
        changeData(value) {
            if (this.form.effectFrm) {
                let m = 1;
                if (parseInt(value) == 3) {
                    m = 12;
                } else if (parseInt(value) == 4) {
                    m = 6;
                } else if (parseInt(value) == 5) {
                    m = 3;
                } else if (parseInt(value) == 6) {
                    m = 1;
                }
                this.form.endDay = this.addMonth(this.dateToString(this.form.effectFrm), m);
                this.$forceUpdate();
            }
        },
        getCardType() {
            getCardType(1).then(res => {
                res.data.data.forEach(e => {
                    if (e.key) {
                        e.key = Number(e.key);
                    }
                });
                this.TIME_TYPE_DATA = res.data.data;
            });
            getCardType(0).then(r => {
                r.data.data.forEach(e => {
                    if (e.key) {
                        e.key = Number(e.key);
                    }
                });
                this.CARD_TYPE_DATA = r.data.data;
            });
            getCardType().then(res => {
                res.data.data.forEach(e => {
                    if (e.key) {
                        e.key = Number(e.key);
                    }
                });
                this.ALL_TYPE_DATA = res.data.data;
                let ci = 0;
                this.option.column.forEach(c => {
                    if (c.prop == 'timeType') {
                        let _this = this;
                        _this.option.column[ci].dicData = this.ALL_TYPE_DATA;
                        _this.$forceUpdate();
                    }
                    ci += 1;
                });
            });
        },
        dateToString(d) {
            let date = new Date(d);
            var year = date.getFullYear();
            var month = (date.getMonth() + 1).toString();
            var day = date.getDate().toString();
            if (month.length == 1) {
                month = '0' + month;
            }
            if (day.length == 1) {
                day = '0' + day;
            }
            var dateTime = year + '-' + month + '-' + day;
            return dateTime;
        },
        addMonth(d, m) {
            var ds = d.split('-'),
                _d = ds[2] - 0;
            var nextM = new Date(ds[0], ds[1] - 1 + m + 1, 0);
            var max = nextM.getDate();
            d = new Date(ds[0], ds[1] - 1 + m, _d > max ? max : _d);
            var ds2 = d.toLocaleDateString().match(/\d+/g).join('-');
            ds2 = ds2.split('-');
            ds = ds2[0];
            if (parseInt(ds2[1]) < 10) ds += '-0' + ds2[1];
            else ds += '-' + ds2[1];
            if (parseInt(ds2[2]) < 10) ds += '-0' + ds2[2];
            else ds += '-' + ds2[2];
            return ds;
        },
        addGnrlCard() {
            let _this = this;
            let ci = 0;
            let tag = _this.GENERAL_CARD_TAG;
            _this.form.bizType = 'SPT';
            this.cardTimeType = 'ptk';
            _this.CARD_TIME_TYPE = 0;
            _this.option.column.forEach(c => {
                if (c.prop in tag) {
                    if (tag[c.prop] == 1) {
                        _this.option.column[ci].addDisplay = true;
                        _this.option.column[ci].rules[0].required = true;
                    } else {
                        _this.option.column[ci].addDisplay = false;
                        _this.option.column[ci].rules[0].required = false;
                    }
                    if (c.prop == 'timeType') {
                        let dc = _this.TIME_TYPE_DATA;
                        _this.option.column[ci].dicData = dc;
                    }

                    if (c.prop == 'cardType') {
                        let dc = _this.CARD_TYPE_DATA;
                        _this.option.column[ci].dicData = dc;
                    }
                }
                if (c.prop == 'headFiles') {
                    _this.option.column[ci].tip = '只能上传一张jpg/png用户头像，且不超过500kb';
                    _this.option.column[ci].limit = 1;
                }

                ci += 1;
            });
            _this.$forceUpdate();
            this.$refs.crud.rowAdd();
        },
        addTimeCard() {
            this.cardTimeType = 'year';
            let _this = this;
            let ci = 0;
            _this.form.bizType = 'SPT';
            let tag = _this.GENERAL_CARD_TAG;
            _this.CARD_TIME_TYPE = 1;
            _this.option.column.forEach(c => {
                if (c.prop in tag) {
                    if (tag[c.prop] == 1) {
                        if (c.prop == 'effectFrm' || c.prop == 'endDay') {
                            _this.option.column[ci].addDisplay = true;
                            _this.option.column[ci].rules[0].required = true;
                        } else {
                            _this.option.column[ci].addDisplay = false;
                            _this.option.column[ci].rules[0].required = false;
                        }
                    } else {
                        _this.option.column[ci].addDisplay = true;
                        _this.option.column[ci].rules[0].required = true;
                    }

                    if (c.prop == 'timeType') {
                        //var dc = _this.TIME_TYPE_DATA;
                        //dc.splice(0,1);
                        var dc = [];
                        for (var i = 0; i < _this.TIME_TYPE_DATA.length; i++) dc.push(_this.TIME_TYPE_DATA[i]);
                        _this.option.column[ci].dicData = dc;
                    }
                    if (c.prop == 'cardType') {
                        //var dc = _this.TIME_TYPE_DATA;
                        //dc.splice(0,1);
                        var dc = [];
                        for (var i = 0; i < _this.CARD_TYPE_DATA.length; i++) dc.push(_this.CARD_TYPE_DATA[i]);
                        _this.option.column[ci].dicData = dc;
                    }
                }
                if (c.prop == 'headFiles') {
                    _this.option.column[ci].tip = '最多上传5张jpg/png用户头像，且不超过500kb';
                    _this.option.column[ci].limit = 5;
                }
                ci += 1;
            });
            _this.$forceUpdate();
            _this.$refs.crud.rowAdd();
        },
        gotoBackCard(row) {
            let _this = this;
            if (row.timeType != 0) {
                _this.$message({
                    type: 'error',
                    message: '只有普通卡才能退卡!',
                });
                return;
            }
            this.$confirm('确定发起退卡流程?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                //状态，1=有效卡/0=已退卡/2=制卡申请/3=退卡申请
                toogleStatus(row.id, 3).then(res => {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    //启动流程
                    startFlow(row.id, 'BIZ_SPT_CARD_BACK', 'SUBMIT', {
                        title: '会员：' + _this.accInfo.vipName + '/' + _this.accInfo.vipPhone + '退卡申请',
                    }).then(res2 => {
                        _this.onLoad(_this.page);
                    });
                });
            });
        },
        handleBack() {
            this.$router.go(-1);
        },
        rowSave(row, done, loading) {
            console.log(123);
            let _this = this;
            if (row.headFiles && row.headFiles.length > 0) {
                let iconId = [];
                row.headFiles.forEach(e => {
                    if (e.id) {
                        iconId.push(e.id);
                    } else if (e.label) {
                        iconId.push(e.label);
                    }
                });
                row.iconIds = iconId.toString();
            }

            if (_this.CARD_TIME_TYPE == 0) {
                row.timeType = 0;
                if (row.cardType == 1) row.cardValue = row.faceValue;
                row.balanceValue = row.cardValue + row.giftValue;
                if (row.faceValue < 0 || row.cardValue < 0 || row.giftValue < 0) {
                    this.$message({
                        type: 'error',
                        message: '数值不能小于零!',
                    });
                    loading();
                    return;
                }
            } else {
                row.giftValue = 0.0;
                row.balanceValue = row.faceValue;
                row.cardValue = row.faceValue;
                row.disRate = 1.0;
                row.typeId = row.typeId;
                row.cardType = 0;
                // 新增年卡
                // _this.allCardType.forEach((item) => {
                //   if (item.name.indexOf("游泳") > -1) {
                //     row.typeId = item.id;
                //   }
                // });
                //计算结束时间
                //console.log('mmm:'+this.addMonth('2023-02-28',12));
                let m = 1;
                //label:'年卡',value:1},{label:'半年卡',value:2},{label:'季卡',value:3},{label:'月卡',value:4
            }
            //return;
            row.accId = this.accId;
            if (row.bizType == 'COD') {
                if (row.typeId != 0) {
                    _this.$message({
                        type: 'error',
                        message: '业务类型会场的只能选择通用类型!',
                    });
                    loading();
                    return;
                }
            }
            if (row.cardType === 1) {
                row.cardValue = row.faceValue;
            }
            // if (row.disRate) {
            //   row.disRate = row.disRate * 10;
            // }
            let obj = JSON.parse(JSON.stringify(row));
            if (this.cardTimeType == 'ptk') {
                row.timeType = obj.cardType;
            }
            if (this.cardTimeType == 'year') {
                row.cardType = obj.timeType;
            }
            if (!row.id) {
                add(row).then(
                    res => {
                        //this.onLoad(this.page);
                        _this.$message({
                            type: 'success',
                            message: '保存操作成功!',
                        });
                        //启动流程
                        startFlow(res.data.data.id, 'BIZ_SPT_CARD_APPLY', 'SUBMIT', {
                            title: '会员：' + _this.accInfo.vipName + '/' + _this.accInfo.vipPhone + '新卡申请',
                        }).then(res2 => {
                            _this.onLoad(_this.page);
                            done();
                        });
                    },
                    error => {
                        loading();
                        window.console.log(error);
                    },
                );
            } else {
                update(row).then(
                    () => {
                        this.onLoad(this.page);
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                    },
                    error => {
                        loading();
                        console.log(error);
                    },
                );
            }
        },
        rowUpdate(row, index, done, loading) {
            console.log(row, this.form.childList);

            if (row) {
                row.endDay = row.endDay;
            }
            let _this = this;
            if (row.faceValue < 0 || row.cardValue < 0 || row.giftValue < 0) {
                this.$message({
                    type: 'error',
                    message: '数值不能小于零!',
                });
                loading();
                return;
            }
            if (row.headFiles && row.headFiles.length > 0) {
                let iconId = [];
                row.headFiles.forEach(e => {
                    if (e.id) {
                        iconId.push(e.id);
                    } else if (e.label) {
                        iconId.push(e.label);
                    }
                });
                row.iconIds = iconId.toString();
                row.avatar = iconId.toString();
            } else {
                row.iconIds = '';
                row.avatar = '';
            }

            if (_this.CARD_TIME_TYPE == 0) {
                row.timeType = 0;
                if (row.cardType == 1) row.cardValue = row.faceValue;
                row.balanceValue = row.cardValue + row.giftValue;
                if (row.faceValue < 0 || row.cardValue < 0 || row.giftValue < 0) {
                    this.$message({
                        type: 'error',
                        message: '数值不能小于零!',
                    });
                    loading();
                    return;
                }
            } else {
                row.giftValue = 0.0;
                row.balanceValue = row.faceValue;
                row.cardValue = row.faceValue;
                row.disRate = 1.0;
                row.typeId = 0;
                row.cardType = 0;
                // 新增年卡
                _this.allCardType.forEach(item => {
                    if (item.name.indexOf('游泳') > -1) {
                        row.typeId = item.id;
                    }
                });
                //计算结束时间
                //console.log('mmm:'+this.addMonth('2023-02-28',12));
                let m = 1;
                //label:'年卡',value:1},{label:'半年卡',value:2},{label:'季卡',value:3},{label:'月卡',value:4
            }
            //return;
            row.accId = this.accId;
            if (row.bizType == 'COD') {
                if (row.typeId != 0) {
                    _this.$message({
                        type: 'error',
                        message: '业务类型会场的只能选择通用类型!',
                    });
                    loading();
                    return;
                }
            }
            if (row.cardType === 1) {
                row.cardValue = row.faceValue;
            }
            // if (row.disRate) {
            //   row.disRate = row.disRate * 10;
            // }
            let obj = JSON.parse(JSON.stringify(row));
            if (this.cardTimeType == 'ptk') {
                row.timeType = obj.cardType;
            }
            if (this.cardTimeType == 'year') {
                row.cardType = obj.timeType;
            }
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    // this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            console.log(type);
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.cardNo).then(res => {
                    let _this = this;
                    _this.form = res.data.data;
                    if (this.form.cardType == 3) {
                        let ci = 0;
                        _this.option.column.forEach(c => {
                            if (c.prop == 'timeType') {
                                _this.option.column[ci].addDisplay = true;
                                _this.option.column[ci].editDisplay = true;
                                _this.option.column[ci].viewDisplay = true;
                                _this.$forceUpdate();
                            }
                            if (c.prop == 'cardType') {
                                _this.option.column[ci].addDisplay = false;
                                _this.option.column[ci].editDisplay = false;
                                _this.option.column[ci].viewDisplay = false;
                                _this.$forceUpdate();
                            }
                            ci += 1;
                        });
                    } else {
                        let ci = 0;
                        _this.option.column.forEach(c => {
                            if (c.prop == 'timeType') {
                                _this.option.column[ci].addDisplay = false;
                                _this.option.column[ci].editDisplay = false;
                                _this.option.column[ci].viewDisplay = false;
                                _this.$forceUpdate();
                            }
                            if (c.prop == 'cardType') {
                                _this.option.column[ci].addDisplay = true;
                                _this.option.column[ci].editDisplay = true;
                                _this.option.column[ci].viewDisplay = true;
                                _this.$forceUpdate();
                            }
                            ci += 1;
                        });
                    }
                    if (this.form.childList.length > 0) {
                        let headFiles = [];
                        this.form.childList.forEach(p => {
                            getPicListByIds(p.iconId).then(r => {
                                let res = r.data.data[0];
                                headFiles.push({
                                    id: res.id,
                                    value: res.link,
                                    url: res.url,
                                    label: '创建人脸结果' + p.createResult + '' + '下发人脸权限' + p.issueResult,
                                });
                            });
                        });
                        this.form.headFiles = headFiles;
                    }
                    this.allCardType.forEach(type => {
                        if (this.form.typeId == type.id) {
                            console.log(this.form);
                            this.form.typeName = type.name;
                        }
                    });

                    getUser(this.form.creatorId).then(res2 => {
                        _this.form.creatorName = res2.data.data.name;
                    });
                });
            }
            done();
        },
        beforeClose(done, type) {
            let _this = this;
            let ci = 0;
            _this.option.column.forEach(c => {
                if (c.prop == 'timeType') {
                    _this.option.column[ci].dicData = this.ALL_TYPE_DATA;
                    _this.$forceUpdate();
                }
                ci += 1;
            });
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            // this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            if (this.accId == '') return;
            this.loading = true;
            this.query.accId = this.accId;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                data.records.forEach(c => {
                    this.allCardType.forEach(type => {
                        if (c.typeId == type.id) {
                            c.typeName = type.name;
                        }
                    });
                });

                this.data = data.records;
                this.loading = false;
                let ci = 0;
                let _this = this;
                _this.option.column.forEach(c => {
                    if (c.prop == 'timeType') {
                        _this.option.column[ci].dicData = this.ALL_TYPE_DATA;
                        _this.$forceUpdate();
                    }
                    if (c.prop == 'cardType') {
                        _this.option.column[ci].dicData = this.ALL_TYPE_DATA;
                        _this.$forceUpdate();
                    }
                    // if (c.prop == "typeList") {
                    //   _this.option.column[ci].dicData = this.ALL_TYPE_DATA;
                    //   _this.$forceUpdate();
                    // }
                    ci += 1;
                });

                this.selectionClear();
            });
        },
    },
};
</script>

<style></style>
