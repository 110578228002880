<template>
  <basic-container>
    <avue-crud
      :option="option"
      :data="todoList"
      ref="crud"
      @on-load="getTodoList"
    >
      <template slot="over_day" slot-scope="{row}">
        {{row.over_day}}天
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
export default {
  data() {
    return {
      todoList: [],

      option: {
        height: "400px",
        calcHeight: 30,
        tip: false,
        searchShow: false,
        searchMenuSpan: 6,

        border: true,
        index: true,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        viewBtn: false,
        labelWidth: 120,
        menu: false,
        column: [
          {
            label: "会员卡号",
            prop: "card_nos",
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            searchShow: true,
            label: "延期天数",
            prop: "over_day",
            // hide: true,
          },
          {
            label: "操作人",
            prop: "create_user_name",
            search: false,
          },
          {
            searchShow: true,
            label: "操作时间",
            prop: "create_time",
            width: 150
          },
          {
            label: "修改时间",
            prop: "update_time",
            search: false,
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {},
  watch: {
    currentId(val) {
      if (val) {
        console.log("qingqiuqsadf");
        this.getTodoList(val);
      }
    },
  },
  props: {
    currentId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  methods: {
    getTodoList(id) {
      if (id) {
        let obj = {
          size: 1000,
          current: 1,
          wheres: [{ field: "card_ids", value: this.currentId }],
        };
        getMetaCommonList(
          "wisdom_park.wp_spcod_account_card_over_datail",
          obj
        ).then((res) => {
          const data = res.data.data;
          this.todoList = data.records;
        });
      }
    },
  },
};
</script>

<style></style>
